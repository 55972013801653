<template>
	<div class="bicolor">
		<!-- 材料导航 -->
		<material-banner :menuIndex="1"></material-banner>
		
		<!-- 产品特点 -->
		<div class="region-com region-trait k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.Feature') }}</span>
				<span class="region-title-deputy">{{ $t('m.Higheffecient') }}</span>
			</div>
			<img :src="paperBicolorUrl+$t('m.trait')" class="region-cont">
			<div class="region-desc">
				<div class="region-desc-single k_flex_column_aifs">
					<span class="region-desc-single-main">{{ $t('m.Strikewithcolour') }}</span>
					<span class="region-desc-single-deputy">{{ $t('m.Youcanprintout') }}</span>
				</div>
				<div class="region-desc-line"></div>
				<div class="region-desc-single k_flex_column_aifs">
					<span class="region-desc-single-main">{{ $t('m.Rejectblack') }}</span>
					<span class="region-desc-single-deputy">{{ $t('m.Standoutfrom') }}</span>
				</div>
				<div class="region-desc-line"></div>
				<div class="region-desc-single k_flex_column_aifs">
					<span class="region-desc-single-main">{{ $t('m.Keepupwithwhat') }}</span>
					<span class="region-desc-single-deputy">{{ $t('m.exclusiveselfdeveloped') }}</span>
				</div>
			</div>
		</div>
		
		<!-- 餐饮行业 -->
		<div class="region-com region-restaurant k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{$t('m.Catering')  }}</span>
				<span class="region-title-deputy">{{$t('m.Customerswill')  }}</span>
			</div>
			<img :src="paperBicolorUrl+$t('m.restaurant')" class="region-cont">
			<div class="region-desc k_flex_jcc_aic">
				<span>{{$t('m.Marketing')  }}</span><span> - </span><span>{{$t('m.Instantmarketing')  }}</span>
			</div>
		</div>
		
		<!-- 物流行业 -->
		<div class="region-com region-logistics k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{$t('m.LogisticsIndustry')  }}</span>
				<span class="region-title-deputy">{{$t('m.Byusingdualcolour')  }}</span>
			</div>
			<img :src="paperBicolorUrl+$t('m.logistics')" class="region-cont">
			<div class="region-desc k_flex_jcc_aic">
				<span>{{$t('m.LogisticsManagement')}}</span><span> - </span><span>{{$t('m.efficientsorting')  }}</span>
			</div>
		</div>
		
		<!-- 医疗行业 -->
		<div class="region-com region-hospital k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{$t('m.MedicalIndustry')}}</span>
				<span class="region-title-deputy" >{{$t('m.Theuseofdualcolour')}}</span>
			</div>
			<img :src="paperBicolorUrl+$t('m.hospital')" class="region-cont">
			<div class="region-desc k_flex_jcc_aic">
				<span>{{$t('m.HighEfficiency')}}</span><span> - </span><span>{{$t('m.Thermalprinting')}}</span>
			</div>
		</div>
		
		<!-- 产品规格 -->
		<div class="region-com region-size k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{$t('m.TechnicalSpecification')}}</span>
				<span class="region-title-dashed"></span>
			</div>
			<div class="region-cont">
				<el-table
					:data="tableData"
					:span-method="objectSpanMethod"
					border
					style="width: 100%;font-size: 16px;">
					<el-table-column
						prop="name"
						label="产品名称"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Product')}}</div>
						</template>
						<template slot-scope="scope">
							<div style="white-space: pre-wrap;">
								{{ scope.row.name }}
							</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="type"
						label="产品型号"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Model')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="standards"
						label="产品规格"
						align="center"
						width="160">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Specification')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="metre"
						label="米数"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Length')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="introduce"
						label="介绍"
						align="center"
						width="599">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Introduction')}}</div>
						</template>
						<template slot-scope="scope">
							<div class="region-cont-introduce">
								<div class="region-cont-introduce-single" v-for="(item,index) in scope.row.introduce">
									<span>{{item.title}}</span>
									<span>{{item.content}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<img :src="paperBicolorUrl+$t('m.paperbicolorsizepng')" class="region-desc">
		</div>
	</div>
</template>

<script>
import materialBanner from '@c/materialBanner/materialBanner'
export default{
	components:{
		materialBanner,
	},
	data() {
		return {
			// tableData: [
			// 	{
			// 		name: '双色热敏纸',
			// 		type: 'DW6809',
			// 		standards: '1090mm',
			// 		metre: '8000m',
			// 		introduce: [
			// 			{
			// 				title:'同时打印两种颜色：',
			// 				content:'黑红、红蓝、黑绿、黑紫、黑玫双色热敏突出重点信息，既具备标识鲜明的特点吸引注意力，传递关键信息，又具备热敏快捷高效可变打印的优势，具体可参考餐饮行业、多行业双色解决方案等ppt；'
			// 			}
			// 		]
			// 	}, 
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1090mm',
			// 		metre: '6000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1550mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1650mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	}
			// ]
		}
	},
	computed: {
		paperBicolorUrl() {
		  return 'https://oss-www.kymasf.com/img/material/paperBicolor/'
		},
		tableData(){
    return [
				{
					name: this.$t('m.Dualcolourthermalpaper'),
					type: 'DW6809',
					standards: '1090mm',
					metre: '8000m',
					introduce: [
						{
							title:this.$t('m.title'),
							content:this.$t('m.Simultaneousprinting')
						}
					]
				}, 
				{
					name: '',
					type: '',
					standards: '1090mm',
					metre: '6000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1550mm',
					metre: '12000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1650mm',
					metre: '12000m',
					introduce: []
				}
			]
		} 
		
	},
	mounted() {
		
	},
	methods: {
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
				if (rowIndex % 4 === 0) {
					return {
						rowspan: 4,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.bicolor{
	width: 100%;
	height: 100%;
	background: #fff;
	// 区域公共样式
	.region-com{
		width: 100%;
		.region-title{
			.region-title-main{
				margin-top: 48px;
				height: 48px;
				font-size: 42px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64;
				line-height: 48px;
				border-bottom: 2px dashed #535353;
				padding-bottom: 15px;
				margin-bottom: 15px
			}
			.region-title-deputy{
				width: 981px;
				height: 44px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2C3F64;
				line-height: 24px;
				text-align: center;
			}
		}
	}
	
	//产品特点
	.region-trait{
		.region-title{
			margin-top: 36px;
			.region-title-main{
				font-size: 42px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2E3136;
				margin: 0;
				padding: 0;
				border: none;
			}
			.region-title-deputy{
				margin-top: 10px;
				height: 20px;
				font-size: 20px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64
			}
		}
		.region-cont{
			margin-top: 90px;
			margin-bottom: 60px;
		}
		.region-desc{
			width: 1200px;
			padding: 46px 144px;
			// height: 362px;
			background: #257FDB;
			box-sizing: border-box;
			margin-bottom: 50px;
			.region-desc-single{
				width: 700px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #FFFFFF;
				.region-desc-single-main{
					font-size: 26px;
					line-height: 30px;
					font-weight: 600;
				}
				.region-desc-single-deputy{
					font-size: 22px;
					line-height: 30px;
					font-weight: 200;
				}
			}
			.region-desc-line{
				width: 50px;
				height: 4px;
				background: #EFF1F2;
				margin: 17px 0;
			}
		}
	}
	
	//餐饮行业
	.region-restaurant{
		background: #EFF1F2;
		.region-title{
			.region-title-deputy{
				width: 700px;
				height: 44px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2C3F64;
				line-height: 24px;
				text-align: center;
			}
		}
		.region-cont{
			margin-top: 60px;
			// margin-bottom: 60px;
		}
		.region-desc{
			width: 1200px;
			margin-top: 10px;
			margin-bottom: 50px;
			span{
				&:nth-child(1){
					font-size: 20px;
					height: 28px;
					line-height: 28px;
				}
				&:nth-child(2){
					margin: 0 5px;
					font-size: 20px;
					height: 28px;
					line-height: 28px;
				}
				&:nth-child(3){
					font-size: 16px;
					height: 22px;
					line-height: 22px;
				}
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64;
			}
		}
	}
	
	//物流行业
	.region-logistics{
		.region-cont{
			margin-top: 80px;
			// margin-bottom: 80px;
		}
		.region-desc{
			width: 1200px;
			margin-top: 6px;
			margin-bottom: 50px;
			span{
				&:nth-child(1){
					font-size: 20px;
					height: 28px;
					line-height: 28px;
				}
				&:nth-child(2){
					margin: 0 5px;
					font-size: 20px;
					height: 28px;
					line-height: 28px;
				}
				&:nth-child(3){
					font-size: 16px;
					height: 22px;
					line-height: 22px;
				}
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64;
			}
		}
	}
	
	//医疗行业
	.region-hospital{
		background: #EFF1F2;
		.region-cont{
			margin-top: 40px;
			// margin-bottom: 140px;
		}
		.region-desc{
			width: 1200px;
			margin-bottom: 50px;
			span{
				&:nth-child(1){
					font-size: 20px;
					height: 28px;
					line-height: 28px;
				}
				&:nth-child(2){
					margin: 0 5px;
					font-size: 20px;
					height: 28px;
					line-height: 28px;
				}
				&:nth-child(3){
					font-size: 16px;
					height: 22px;
					line-height: 22px;
				}
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64;
			}
		}
	}
	
	//产品规格
	.region-size{
		background: #3F4346;
		.region-title{
			.region-title-main{
				color: #fff;
				border-bottom: 2px dashed #fff;
			}
		}
		.region-cont{
			margin-top: 30px;
			width: 1200px;
			background: #257FDB;
			padding: 10px;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 16px;
			.region-cont-introduce{
				.region-cont-introduce-single{
					font-size: 16px;
					text-align: left;
				}
			}
		}
		.region-desc{
			margin-top: 20px;
			margin-bottom: 50px;
		}
	}
}
</style>